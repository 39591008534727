/* eslint-disable camelcase */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, radius } from 'theme'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import Container from 'components/Container'
import Footer from 'components/Footer'
import Link from 'components/Link'
import MasonryLayout from 'components/MasonryLayout'
import Input from 'components/Input'
import search from 'static/svg/search.svg'
import LocalImage from 'components/LocalImage'

const StyledImage = styled(LocalImage)`
  cursor: pointer;
`

const StyledWrapper = styled(Flex)`
  border-radius: 4px;
  border: 1px solid rgb(233, 234, 241);
  box-shadow: rgba(39, 40, 49, 0.05) 0px 2px 7px;
  transition: opacity 0.5s cubic-bezier(0.77, 0.2, 0.05, 1) 0s;
`

const Lists = ({ pageContext: { categories } }) => {
  const [categoriesOnPage, setCategoriesOnPage] = useState([...categories])

  const [searchValue, setSearchValue] = useState('')
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value)
  }
  const handleSubmitSearch = (e) => {
    e.preventDefault()
    if (searchValue) {
      const updatedCategories = []
      categories.forEach((category) => {
        let { lists, name } = category
        lists = lists.filter(({ name }) => name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
        if (lists.length) {
          updatedCategories.push({ name, lists })
        }
      })
      setCategoriesOnPage(updatedCategories)
    } else {
      setCategoriesOnPage([...categories])
    }
  }
  return (
    <App>
      <SEO
        title="Agency Vista Lists"
        description="Find your next agency partner today through our free shortlists of top-performing agencies from around the
            world. Agency Vista uses verified agency information to curate the best companies to help you identify a credible provider that best suits your business needs."
        path="/lists/"
      />
      <Container maxWidth="1080px">
        <Flex mt="xl" flexDirection="column" alignItems="center" textAlign="center">
          <H1>Discover The Top Agencies</H1>
          <Text mt="m" color="secondaryText" opacity={0.8} fontSize="l">
            Find your next agency partner today through our free shortlists of top-performing agencies from around the
            world. Agency Vista uses{' '}
            <Text as="a" href="/trust" fontSize="l" color="primary" target="_blank">
              verified agency information
            </Text>{' '}
            to curate the best companies to help you identify a credible provider that best suits your business needs.
          </Text>
          <Box my="l">
            <form onSubmit={handleSubmitSearch}>
              <Input
                onChange={handleSearchChange}
                width="300px"
                placeholder="Search by list"
                pl="40px"
                iconPosition={{ left: '10px' }}
                iconComp={<StyledImage src={search} onClick={handleSubmitSearch} />}
                hasBoxShadow
                borderRadius={radius.m}
              />
            </form>
          </Box>
          <Grid gridTemplateColumns={['1fr', 'repeat(1, 1fr)']} gridGap="m" width="100%">
            <MasonryLayout>
              {categoriesOnPage.map(({ name, lists, isHidden }) => {
                if (!isHidden) {
                  return (
                    <StyledWrapper
                      flexDirection="column"
                      key={name}
                      backgroundColor={COLOR_CONSTANTS.WHITE}
                      px="m"
                      py="l"
                    >
                      <Text textAlign="left" fontSize="l" mb="m" fontWeight="bold">
                        {name}
                      </Text>
                      {lists.map(({ id, name, slug }) => (
                        <Link
                          key={id}
                          href={`/lists/${slug}`}
                          textAlign="left"
                          fontSize="m"
                          fontWeight="medium"
                          antiAliased
                          color="primary"
                          py="s"
                        >
                          {name}
                        </Link>
                      ))}
                    </StyledWrapper>
                  )
                }
              })}
            </MasonryLayout>
          </Grid>
        </Flex>
      </Container>
      <Box mt="xxl">
        <Footer />
      </Box>
    </App>
  )
}

Lists.propTypes = {
  pageContext: PropTypes.shape({
    categories: PropTypes.array.isRequired,
  }).isRequired,
}

export default Lists
