import React from 'react'
import PropTypes from 'prop-types'
import { space } from 'theme'
import { Grid } from 'components/Layout'

const MasonryLayout = ({ columns, gap, children }) => {
  const columnWrapper = {}
  const result = []
  for (let i = 0; i < columns; i++) {
    columnWrapper[`column${i}`] = []
  }
  for (let i = 0; i < children.length; i++) {
    const columnIndex = i % columns
    columnWrapper[`column${columnIndex}`].push(
      <div key={i} style={{ marginBottom: `${gap}` }}>
        {children[i]}
      </div>
    )
  }
  for (let i = 0; i < columns; i++) {
    result.push(
      <div
        key={i}
        style={{
          flex: 1,
        }}
      >
        {columnWrapper[`column${i}`]}
      </div>
    )
  }
  return (
    <Grid mt="l" gridTemplateColumns={['1fr', 'repeat(2, 1fr)']} gridGap="m">
      {result}
    </Grid>
  )
}

MasonryLayout.defaultProps = {
  columns: 2,
  gap: space.m,
}

MasonryLayout.propTypes = {
  columns: PropTypes.number,
  gap: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
}

export default MasonryLayout
